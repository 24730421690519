import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const MultiLanguageRoute = (props) => {
    const availableLanguages = process.env.REACT_APP_AVAILABLE_LANGUAGES.split(" ");
    const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE;
    const lang = props.computedMatch.params.lang; // Linguagem passada na url
    const isBasePathWithoutLang = props.path === "/" // Verifica se a url não tem linguagem setada
    const isAvailableLanguage = availableLanguages.includes(lang); // Verifica se a linguagem passada é uma das disponíveis
    const storedLang = JSON.parse(localStorage.getItem('language')); // Linguagem armazenada no local storage
    const currentLanguage = storedLang && availableLanguages.includes(storedLang.lang) ? storedLang.lang : defaultLanguage;

    if (isBasePathWithoutLang) { return <Redirect to={`/${currentLanguage}`} /> }
    else if (!isAvailableLanguage) { return <Redirect to="/404" /> } // Direciona para 404 se o parâmetro de linguagem passado não é válido
    else {
        return <Route {...props} />
    }
}

export default MultiLanguageRoute;
