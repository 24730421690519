class SessionListener {
    constructor() {        
        this.reaction = {
            removedItemReaction: null,
            insertedItemReaction: null,
            gettingSessionReaction: null
        };
    }

    onRemovedItem( func ) {
        if ( typeof func === "function" )
            this.reaction.removedItemReaction = func;
    }

    onInsertedItem( func ) {
        if ( typeof func === "function" )
            this.reaction.insertedItemReaction = func;
    }

    onGettingSession( func ) {
        if ( typeof func === "function" )
            this.reaction.gettingSessionReaction = func;
    }

    // transferir session entre abas
    sessionStorageTransfer( event ) {
        if ( !event )
            event = window.event;

        if ( !event.newValue ) 
            return;

        if ( event.key === 'getSessionStorage' ) {
            // outra aba pediu a session entao envia
            window.localStorage.setItem( 
                'sessionStorage', 
                JSON.stringify( window.sessionStorage ) 
            );
            // remove da localStorage logo em seguida
            window.setTimeout(
                window.localStorage.removeItem( 'sessionStorage' ),
                500
            );

        } else if ( event.key === 'sessionStorage' ) {
            // recebeu de outra aba
            let data = JSON.parse( event.newValue );
            for ( let key in data ) {
                window.sessionStorage.setItem( key, data[key] );
                if ( this.reaction.gettingSessionReaction ){
                    this.reaction.gettingSessionReaction( key );
                }
            }

        } else if ( event.key === 'removeItem' ) {
            // outra aba avisou que um item foi removido
            let item = event.newValue;
            window.sessionStorage.removeItem( item );

            if ( this.reaction.removedItemReaction )
                this.reaction.removedItemReaction( item );
        } else if ( event.key === 'insertItem' ) {
            // outra aba avisou que um item foi inserido
            let itemData = JSON.parse( event.newValue );
            for ( let key in itemData ) {
                if ( !window.sessionStorage.getItem( key ) || window.sessionStorage !== itemData[key]) {
                    window.sessionStorage.setItem( key, itemData[key] );
                    if ( this.reaction.insertedItemReaction ) {
                        this.reaction.insertedItemReaction( key );
                    }
                }
            }
        }
    }

    // pedir para as outras abas as credenciais na session
    askForSession() {
        if ( !window.sessionStorage.length ) {
            window.localStorage.setItem( 'getSessionStorage', Date.now() );
            window.setTimeout(
                window.localStorage.removeItem( 'getSessionStorage' ),
                500
            );
        }
    }

    // avisar que removeu um item da session
    removedItem( item ) {
        window.localStorage.setItem( 'removeItem', item );
        window.setTimeout(
            window.localStorage.removeItem( 'removeItem' ),
            500
        );
    }

    // avisar que inseriu um item na session
    insertedItem( item ) {
        let itemData = {};
        itemData[item] = window.sessionStorage.getItem( item );

        window.localStorage.setItem( 
            'insertItem', 
            JSON.stringify( itemData )
        );
        window.setTimeout(
            window.localStorage.removeItem( 'insertItem' ),
            500
        );

    }

    // escutar as mudanças na localStorage
    listen() {
        window.addEventListener( 
            'storage',
            this.sessionStorageTransfer.bind(this),
            false 
        );
    }
    
}
let sessionListener = new SessionListener();
Object.freeze(sessionListener);
export default sessionListener;