import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import SessionListener from "./utils/session-listener.js";
import "animate.css";
import './styles/main.scss';
import MultiLanguageRoute from "./components/multiLanguageRoute.js";
// import translatedRoutes from "./utils/translatedRoutes.js";

const Carreira = lazy(() => retry(() => import("./views/carreiras")));
const Home = lazy(() => retry(() => import("./views/home/index.js")));
const Vagas = lazy(() => retry(() => import("./views/vagas")));
// const Planos = lazy(() => retry(() => import("./views/planos")));
// const CentralCliente = lazy(() => retry(() => import("./views/centralCliente")));
// const Pedido = lazy(() => retry(() => import("./views/pedido")));
const DescricaoVaga = lazy(() => retry(() => import("./views/descricao-vaga")));
// const LPTrainee = lazy(() => retry(() => import("./views/lp-trainee.jsx")));
// const LPTraineeForm = lazy(() => retry(() => import("./views/lp-trainee-form.jsx")));
// const LPTraineeConfirmation = lazy(() => retry(() => import("./views/lp-trainee-confirmation.jsx")));
// const FinalizarPedido = lazy(() => retry(() => import("./views/finalizarPedido")));
const Talento = lazy(() => retry(() => import("./views/talento")));
// const PedidoConfirmado = lazy(() => retry(() => import("./views/pedidoConfirmado")));
const Erro404 = lazy(() => retry(() => import("./views/erro404")));
const TraineeCandidaturePage = lazy(() => retry(() => import("./views/careers/trainee/index.jsx")));
const LPTraineeConfirmation = lazy(() => retry(() => import("./views/careers/traineeConfirmation/index.jsx")));


const Contato = lazy(() => retry(() => import("./views/contato")));
const PoliticaPrivacidade = lazy(() => retry(() => import("./views/politica-de-privacidade")));


function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}


class App extends React.Component {
  render() {
    SessionListener.listen();

    return (
      <Router>
        
        <Suspense fallback={<div   translate="no" className="notranslate lev-loading-test" style={{ marginTop: "10%" }}></div>}>
          <Switch>
            <Route exact path="/404" component={Erro404} /> 
            <Route exact path="/carreiras" component={Carreira} />
            <Route exact path="/talento" component={Talento} />
            
            {/* <Redirect from="/trainee" to="/carreiras/trainee" /> */}
            <Route exact path="/carreiras/vagas" component={Vagas} />
            <Route exact path="/carreiras/talento" component={Talento} />
            <Route exact path="/carreiras/vaga-:spId" component={DescricaoVaga} />
            
            {/* <Route exact path="/carreiras/trainee" component={LPTrainee} /> */}
            {/* <Route exact path="/carreiras/trainee-form" component={LPTraineeForm} /> */}

            <Route exact path="/carreiras/trainee" render={() => <Redirect to="/carreiras/trainee/" />} />
            <Route exact path="/carreiras/trainee-form" component={TraineeCandidaturePage} />
            <Route exact path="/carreiras/trainee-confirmation" component={LPTraineeConfirmation} />
            
            
            {/* <Route exact path="/planos" component={Planos} /> */}
            {/* <Route exact path="/pedido/plano/:planId" component={Pedido} /> */}
            {/* <Route exact path="/pedido/plano/:planId/item/:selectedComponentId" component={FinalizarPedido} /> */}
            {/* <Route exact path="/central-do-cliente" component={CentralCliente} /> */}
            {/* <Route exact path="/pedido-confirmado" component={PedidoConfirmado} /> */}
            {/* Criar página 404 */}
            
            <MultiLanguageRoute exact path="/"/>
            <MultiLanguageRoute exact path="/:lang" component={Home} teste={'teste'}/>
            {/* <MultiLanguageRoute exact path="/:lang/contato" component={Contato}/> */}

            {/* Fazer as rotas traduzidas de forma dinâmica */}
            <Route exact path="/en/contact" component={Contato} />
            <Route exact path="/br/contato" component={Contato} />
            <Route exact path="/es/contacto" component={Contato} />
            
            
            {/* <MultiLanguageRoute exact path="/:lang/politica-de-privacidade" component={PoliticaPrivacidade}/> */}

            {/* Fazer as rotas traduzidas de forma dinâmica */}
            <Route exact path="/en/privacy-policy" component={PoliticaPrivacidade} />
            <Route exact path="/br/politica-de-privacidade" component={PoliticaPrivacidade} />
            <Route exact path="/es/politica-de-privacidad" component={PoliticaPrivacidade} />

            <Redirect from="*" to="/404" />
          </Switch>
        </Suspense>
      </Router>
    );

  }
}

export default App;
